<template>
  <div class="flex flex-col">
    <div class="flex-1">
      <div class ="plain">
        <div class="container pt-12 pl-5 pr-4 mx-auto mb-4 lg:pr-20">
          <h1 class="mt-4 mb-6 font-extrabold text-center lg:mb-10 text-mhead lg:text-head">Privacy policy</h1>
          <p>We value privacy, and therefore, I we also believe that your privacy is very important.</p>

          <p>This privacy policy applies to any personal information given and received. We define personal information as anything that would personally identify you: your name, your phone & email contact information, your physical and/or mailing address, your preferences, and other information that is not generally available to the public. Of course, this policy applies to ArmadilloMD.com and no other companies or entities.</p>

          <p>ArmadilloMD.com gathers personal information about you (name and email address) when you sign up for our newsletter. Also, your browser gives us your IP address and cookie information.</p>

          <p>We NEVER rent or sell your private information. You will not receive spam from us, and your information is safe and secure.</p>

          <p>What we may do with your information:</p>

          <p>We might personalize your experience and it allows me to talk to you, improve service to you, and provide strictly anonymous reporting to other clients.</p>

          <p>Children under 13 must have a parent or guardian approve registry with ArmadilloMD. We will not contact children under 13 for personal information, sales promotions, or marketing purposes.</p>

          <p>We do not rent, sell, give or share any of your personal information unless it’s to provide a service or product YOU request, or is regulated by confidential requirements. We value my relationship with you and your privacy. Your information is never made available without your explicit consent.</p>

          <p>You are always free to terminate your relationship with ArmadilloMD.com and request that your information be removed from our system. Simply click the link at the bottom of any one of the emails you receive to unsubscribe at any time.</p>
        </div>
      </div>
    </div>
    <footer-md class="px-10 mt-10 text-gray-700 lg:px-0 lg:mt-0" />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
export default {
  components: {
    "footer-md": Footer
  }
};
</script>
